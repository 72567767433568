// Dropdown is the nav bar user profile component
.dropdown-menu {
    background-color: var(--dropdown-item-bg-color);

    .dropdown-item {
        color: var(--dropdown-item-text-color); 
        background-color: var(--dropdown-item-bg-color);
        cursor: pointer;

        &:hover, &:focus {
            color: var(--dropdown-item-hover-text-color); 
            background-color: var(--dropdown-item-hover-bg-color);
            text-decoration: none;
        }
    }
}

.dropdown {
    //z-index: 1055 !important; // ngb v12 bug: https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
}
.tagbadge {
    border: 1px solid var(--tagbadge-border-color);
    color: var(--tagbadge-text-color);
    background-color: var(--tagbadge-bg-color);
}

.typeahead-input .tagbadge {
    border: 1px solid var(--tagbadge-typeahead-border-color);
    color: var(--tagbadge-typeahead-text-color);
    background-color: var(--tagbadge-typeahead-bg-color);
}
